import React, { useContext } from 'react';
import {
  Box,
  CloseButton,
  createStyles,
  Flex,
  MediaQuery,
  Stack,
} from '@mantine/core';
import CategoryFilter from '../CategoryFilter/CategoryFilter';
import NoozButton from '@/components/Nooz/NoozButton/NoozButton';
import { AdjustmentsHorizontal } from 'tabler-icons-react';
import { CategoryFilterSectionContext } from '@/templates/ProductCategory';
import {
  SHOP_ITEMS_ACTION,
  useShopItems,
  useShopItemsDispatch,
} from '@/templates/ProductCategory.context';
import useStaticContent from '@/utils/hook/useStaticContent';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import clientSideOnly from '@/utils/context/clientSideOnly';
import usePageContext from '@/utils/context/page';

const useStyles = createStyles((theme) => ({
  container: {
    paddingRight: 40,
    position: 'sticky',
    top: 0,
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      paddingRight: 0,
    },
  },
}));

export const CategoryFiltersHeader = () => {
  //@ts-ignore
  const { toggle } = useContext(CategoryFilterSectionContext);
  const staticContent = useStaticContent();

  return (
    <Flex
      mb={18}
      justify='space-between'
      align='center'
      w='100%'>
      <NoozText
        weight='bold'
        size={24}>
        {staticContent.categoryFilters.filters}
      </NoozText>
      <CloseButton
        onClick={toggle}
        size='md'
        radius='xl'
      />
    </Flex>
  );
};

const CategoryFilters = ({ facets }: { facets?: any }) => {
  const {
    shopitems: { filters },
  } = usePageContext();
  const { nbFilters } = useShopItems();
  const dispatch = useShopItemsDispatch();
  const { classes } = useStyles();
  const staticContent = useStaticContent();

  return (
    <Box
      className={classes.container}
      pb={20}>
      <MediaQuery
        smallerThan='md'
        styles={{ display: 'none' }}>
        <CategoryFiltersHeader />
      </MediaQuery>
      <NoozButton
        disabled={nbFilters === 0}
        onClick={() => {
          dispatch({
            type: SHOP_ITEMS_ACTION.RESET,
          });
        }}
        mb={30}
        leftIcon={<AdjustmentsHorizontal strokeWidth={1} />}
        color='black'
        variant='outline'
        fullWidth>
        {staticContent.categoryFilters.reinitFilters}
      </NoozButton>
      <Stack spacing='xl'>
        {/*<CategoryFilter*/}
        {/*  attribute='product.category.title'*/}
        {/*  values={filters['product.fields.category.fields.title']}*/}
        {/*  label={staticContent.categoryFilters.filterLabels.categoryLabel}*/}
        {/*/>*/}
        <CategoryFilter
          attribute='shape'
          values={filters['shape']}
          label={staticContent.categoryFilters.filterLabels.shapeLabel}
        />
        <CategoryFilter
          attribute='style'
          values={filters['style']}
          label={staticContent.categoryFilters.filterLabels.styleLabel}
        />
        <CategoryFilter
          attribute='sexe'
          values={filters['sexe']}
          label={staticContent.categoryFilters.filterLabels.sexeLabel}
        />
        <CategoryFilter
          attribute='material'
          values={filters['material']}
          label={staticContent.categoryFilters.filterLabels.materialLabel}
        />
        <CategoryFilter
          values={filters.hue}
          attribute={'hue'}
          label={staticContent.categoryFilters.filterLabels.colorLabel}
        />
      </Stack>
    </Box>
  );
};

export default clientSideOnly(CategoryFilters);
