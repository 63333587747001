import { Box, createStyles, useMantineTheme } from '@mantine/core';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import React, { useEffect, useRef, useState } from 'react';
import NoozBadge, { IBadgeProps } from '@/components/Nooz/NoozBadge/NoozBadge';
import { useMediaQuery } from '@mantine/hooks';
import { ShopItem, ShopItemVariant } from '@/@types/shopitem';

const useStyles = createStyles((theme, { hasPromotion }: any) => ({
  leftBadgeWrapper: {
    ...(hasPromotion ? {} : { display: 'none' }),
    position: 'absolute',
    display: 'flex',
    zIndex: 999,
    top: 20,
    left: 20,
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      top: 10,
      left: 10,
    },
  },
  uspBadgeWrapper: {
    position: 'absolute',
    zIndex: 999,
    top: 20,
    right: 20,
    display: 'flex',
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      top: 10,
      right: 10,
    },
    [theme.fn.smallerThan(theme.breakpoints.xs)]: {
      ...(hasPromotion ? { display: 'none' } : {}),
      top: 12,
    },
    '& > *:not(:last-of-type)': {
      marginRight: '10px',
    },
  },
  whiteBadge: {
    backgroundColor: 'white',
  },
  leftBadge: {
    height: 32,
    width: 32,
    padding: 0,
    [theme.fn.smallerThan(theme.breakpoints.lg)]: {
      height: 20,
      width: 20,
    },
  },
  premiumBadge: {
    height: 32,
    width: 'auto',
    padding: 0,
    [theme.fn.smallerThan(theme.breakpoints.lg)]: {
      height: 20,
    },
  },
}));

const ProductCardBadges = ({
  product,
  variant,
  premiumEnabled,
  forceMini,
}: ShopItem & {
  showAction?: boolean;
  variant: ShopItemVariant;
  type?: any;
  premiumEnabled?: boolean;
  forceMini?: boolean;
}) => {
  const { stateBadge, collectionMainUspBadge } = product;
  const { classes } = useStyles({
    hasPromotion: variant.hasPromotion,
  });
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);

  const div = useRef(null);
  const [isSmall, setIsSmall] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const fct = () => {
        if (div.current) setIsSmall((div.current as any).offsetWidth < 200);
      };
      window.addEventListener('resize', fct);
      return () => {
        window.removeEventListener('resize', fct);
      };
    }
  }, []);
  const hasPromotion = !!variant.compareAtPrice && !!variant.promotion;

  const badgeProps = (
    badge?: any,
    premium?: boolean,
    custom: Record<string, any> | undefined = {},
  ) => ({
    h: isDesktop ? 32 : 20,
    type: badge?.type || 'outline',
    variant: premium ? 'filled' : undefined,
    color: premium ? 'black' : badge?.label?.color?.hex || theme.colors.text[0],
    className: premium ? undefined : classes.whiteBadge,
    ...custom,
  });

  const badgeTextProps = (
    premiumEnabled?: boolean,
    custom: Record<string, any> | undefined = {},
  ) => ({
    size: isDesktop ? 12 : 10,
    weight: 500,
    color: premiumEnabled ? theme.colors.white[0] : 'dimmed',
    ...custom,
  });

  const returnIcon = (
    iconData: any,
    defaultIconKey: any,
    defaultColor: any,
    premium?: boolean,
  ) => {
    return (
      <NoozIcon
        iconKey={iconData?.iconKey || defaultIconKey}
        size={isDesktop ? 17 : 12}
        color={premium ? theme.colors.white[0] : iconData?.hex || defaultColor}
      />
    );
  };

  const LeftBadge = () => {
    if (forceMini) return null;
    let props: IBadgeProps | undefined = undefined;
    if (variant.hasPromotion && variant.promoBadge) {
      const currBadge = variant.promoBadge;
      props = {
        icon: returnIcon(currBadge?.icon, 'Discount', theme.colors.white[0]),
        textProps: badgeTextProps(),
        badgeProps: badgeProps(currBadge, undefined, {
          color: theme.colors.white[0],
          sx: {
            backgroundColor: currBadge?.color?.hex,
          },
        }),
        text: currBadge?.label?.text || '',
      };
    }
    return props ? (
      <NoozBadge
        type='contained'
        {...props}
      />
    ) : null;
  };

  const UspProductBadge = () => {
    if (stateBadge) {
      const type = stateBadge?.color?.gradientObject
        ? 'gradient'
        : stateBadge?.type === 'contained'
        ? 'filled'
        : 'outline';

      return (
        <NoozBadge
          badgeProps={{
            ...badgeProps(stateBadge),
            color:
              type === 'outline' ? stateBadge?.color?.classname : undefined,
            sx: {
              background:
                type === 'outline' ? undefined : stateBadge?.color?.hex,
            },
            gradient: stateBadge?.color?.gradientObject,
            variant: type,
          }}
          textProps={{
            ...badgeTextProps(),
            sx: {
              color:
                type === 'outline'
                  ? stateBadge?.color?.hex || theme.colors.gray[6]
                  : theme.colors.white[0],
            },
          }}
          icon={
            <NoozIcon
              iconKey={stateBadge?.icon?.iconKey || 'Discount'}
              size={isDesktop ? 17 : 12}
              color={
                type === 'outline'
                  ? stateBadge?.color?.hex || theme.colors.gray[6]
                  : theme.colors.white[0]
              }
            />
          }
          text={stateBadge?.label?.text || ''}
        />
      );
    } else if (premiumEnabled) {
      return variant.new && forceMini ? (
        <NoozBadge
          iconPosition={'right'}
          type='contained'
          badgeProps={{
            variant: 'filled',
            color: 'black',
            h: isDesktop ? 32 : 20,
            p: 3,
          }}
          icon={
            <NoozIcon
              iconKey={'Crown'}
              size={isDesktop ? 17 : 12}
              color={theme.colors.white[9]}
            />
          }
          textProps={{
            ...badgeTextProps(),
            color: theme.colors.white[0],
          }}
          text=''
        />
      ) : (
        <NoozBadge
          iconPosition={'right'}
          type='contained'
          badgeProps={{
            variant: 'filled',
            color: 'black',
            h: isDesktop ? 32 : 20,
          }}
          icon={
            <NoozIcon
              iconKey={'Crown'}
              size={isDesktop ? 17 : 12}
              color={theme.colors.white[9]}
            />
          }
          textProps={{
            ...badgeTextProps(),
            color: theme.colors.white[0],
          }}
          text='Premium'
        />
      );
    }
    const shouldAnimateText =
      isSmall || !!stateBadge || variant.new || !!hasPromotion;

    return (
      <NoozBadge
        shouldAnimateText={shouldAnimateText}
        badgeProps={badgeProps(collectionMainUspBadge, premiumEnabled)}
        textProps={badgeTextProps(premiumEnabled)}
        iconPosition={'right'}
        icon={
          collectionMainUspBadge?.icon
            ? returnIcon(
                collectionMainUspBadge.icon,
                undefined,
                theme.colors.title[0],
                premiumEnabled,
              )
            : undefined
        }
        text={collectionMainUspBadge?.label?.text || ''}
      />
    );
  };

  const RightBadge = () => {
    let txt = undefined;
    if (variant.new) {
      txt = 'New';
    }

    return txt ? (
      <NoozBadge
        badgeProps={badgeProps(undefined, premiumEnabled, {
          ...(premiumEnabled
            ? {}
            : {
                variant: 'gradient',
                gradient: { from: '#1e4ae3', to: '#009fe3', deg: 90 },
                // className: clsx(highlightStyles.classes.outlineButton),
              }),
          ml: 4,
        })}
        textProps={badgeTextProps(
          premiumEnabled,
          premiumEnabled
            ? undefined
            : {
                color: 'white',
                // className: highlightStyles.classes.text,
              },
        )}
        type='contained'
        text={txt}
      />
    ) : null;
  };

  return (
    <>
      <Box
        pos={'absolute'}
        top={0}
        left={0}
        right={0}
        ref={div}
      />
      <Box
        id={'promo'}
        className={classes.leftBadgeWrapper}>
        <LeftBadge />
      </Box>
      <Box
        id={'usp'}
        className={classes.uspBadgeWrapper}>
        <UspProductBadge />
        <RightBadge />
      </Box>
    </>
  );
};

export default ProductCardBadges;
