import { Box, clsx, createStyles, useMantineTheme } from '@mantine/core';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import React, { useEffect, useRef, useState } from 'react';
import NoozBadge from '@/components/Nooz/NoozBadge/NoozBadge';
import { useMediaQuery } from '@mantine/hooks';
import { useHighlightStyles } from '@/utils/style/useHighlightStyles';
import { ShopItem, ShopItemVariant } from '@/@types/shopitem';

const useStyles = createStyles((theme, { hasPromotion, isNew }: any) => ({
  leftBadgeWrapper: {
    ...(hasPromotion || isNew ? {} : { display: 'none' }),
    position: 'absolute',
    display: 'flex',
    zIndex: 999,
    top: 20,
    left: 20,
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      top: 10,
      left: 10,
    },
  },
  uspBadgeWrapper: {
    position: 'absolute',
    zIndex: 999,
    top: 20,
    right: 20,
    display: 'flex',
    [theme.fn.smallerThan(theme.breakpoints.sm)]: {
      top: 10,
      right: 10,
    },
    [theme.fn.smallerThan(theme.breakpoints.xs)]: {
      ...(hasPromotion ? { display: 'none' } : {}),
      top: 12,
    },
    '& > *:not(:last-of-type)': {
      marginRight: '10px',
    },
  },
  whiteBadge: {
    backgroundColor: 'white',
  },
  leftBadge: {
    height: 32,
    width: 32,
    padding: 0,
    [theme.fn.smallerThan(theme.breakpoints.lg)]: {
      height: 20,
      width: 20,
    },
  },
}));

const ProductCardNewBadges = ({
  product,
  variant,
}: ShopItem & {
  showAction?: boolean;
  variant: ShopItemVariant;
  type?: any;
  premiumEnabled?: boolean;
}) => {
  const { stateBadge, collectionMainUspBadge } = product;
  const highlightStyles = useHighlightStyles();
  const { classes } = useStyles({
    hasPromotion: variant.hasPromotion,
    isNew: true,
  });
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);

  const div = useRef(null);
  const [isSmall, setIsSmall] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const fct = () => {
        if (div.current) setIsSmall((div.current as any)?.offsetWidth < 200);
      };
      window.addEventListener('resize', fct);
      return () => {
        window.removeEventListener('resize', fct);
      };
    }
  }, []);
  const hasPromotion = !!variant.compareAtPrice && variant.promotion;

  const badgeProps = (badge?: any) => ({
    h: isDesktop ? 32 : 20,
    color: badge?.label?.color?.hex || theme.colors.text[0],
    className: classes.whiteBadge,
  });

  const badgeTextProps = () => ({
    size: isDesktop ? 12 : 10,
    weight: 500,
    color: 'dimmed',
  });

  const returnIcon = (
    iconData: any,
    defaultIconKey: any,
    defaultColor: any,
  ) => {
    return (
      <NoozIcon
        iconKey={iconData?.iconKey || defaultIconKey}
        size={isDesktop ? 17 : 12}
        color={iconData?.color?.hex || defaultColor}
      />
    );
  };

  const LeftBadge = () => {
    return (
      <NoozBadge
        type='contained'
        badgeProps={{
          ...badgeProps(),
          variant: variant.colorClassname?.includes('.') ? 'filled' : undefined,
          className: variant.colorClassname?.includes('.') ? undefined : clsx(),
          color: variant.colorClassname?.includes('.')
            ? variant.colorClassname
            : theme.colors.white[0],
          sx: variant.colorClassname?.includes('.')
            ? undefined
            : (theme) => ({
                backgroundColor: variant.colorClassname
                  ? theme.colors[variant.colorClassname]?.[0]
                  : undefined,
              }),
        }}
        textProps={badgeTextProps()}
        text='New'
      />
    );
  };

  const SecondaryBadge = () => {
    if (stateBadge) {
      const type = stateBadge.color?.gradientObject
        ? 'gradient'
        : stateBadge.type === 'contained'
        ? 'filled'
        : 'outline';

      return (
        <NoozBadge
          badgeProps={{
            ...badgeProps(stateBadge),
            color: type === 'outline' ? stateBadge.color?.classname : undefined,
            sx: {
              background:
                type === 'outline' ? undefined : stateBadge.color?.hex,
            },
            gradient: stateBadge.color?.gradientObject,
            variant: type,
          }}
          textProps={{
            ...badgeTextProps(),
            sx: {
              color:
                type === 'outline'
                  ? stateBadge.color?.hex || theme.colors.gray[6]
                  : theme.colors.white[0],
            },
          }}
          icon={
            <NoozIcon
              iconKey={stateBadge.icon?.iconKey || 'Discount'}
              size={isDesktop ? 17 : 12}
              color={
                type === 'outline'
                  ? stateBadge.color?.hex || theme.colors.gray[6]
                  : theme.colors.white[0]
              }
            />
          }
          text={stateBadge?.label?.text || ''}
        />
      );
    }
    const shouldAnimateText = isSmall || !!stateBadge || !!hasPromotion;
    return collectionMainUspBadge ? (
      <NoozBadge
        shouldAnimateText={shouldAnimateText}
        badgeProps={badgeProps(collectionMainUspBadge.badge)}
        textProps={badgeTextProps()}
        iconPosition={'right'}
        icon={
          collectionMainUspBadge.badge?.icon
            ? returnIcon(
                collectionMainUspBadge.badge?.icon,
                undefined,
                theme.colors.title[0],
              )
            : undefined
        }
        text={collectionMainUspBadge.badge?.label?.text || ''}
      />
    ) : null;
  };

  const StateBadge = () => {
    if (!!variant.compareAtPrice && variant.promotion) {
      return (
        <NoozBadge
          badgeProps={{
            ...badgeProps(),
            variant: 'gradient',
            className: clsx(highlightStyles.classes.outlineButton),
            ml: 4,
          }}
          textProps={{
            ...badgeTextProps(),
            className: highlightStyles.classes.text,
          }}
          text={variant.promotion?.label?.text || ''}
          type='contained'
        />
      );
    }

    return null;
  };

  return (
    <>
      <Box
        pos={'absolute'}
        top={0}
        left={0}
        right={0}
        ref={div}
      />
      <Box
        id={'promo'}
        className={classes.leftBadgeWrapper}>
        <LeftBadge />
      </Box>
      <Box
        id={'usp'}
        className={classes.uspBadgeWrapper}>
        <SecondaryBadge />
        <StateBadge />
      </Box>
    </>
  );
};

export default ProductCardNewBadges;
