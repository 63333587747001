import { Config } from '@shopstory/core';
import { contentfulPlugin } from '@shopstory/contentful';
import {
  fetchAssetById,
  fetchAssetGetItems,
  fetchContentfulAsset,
} from './fetch/fetchAssets';

const components = [
  {
    id: 'ActionCard',
    label: 'Action Card',
    type: 'card',
    schema: [
      {
        prop: 'size',
        label: 'Size',
        type: 'select',
        options: [
          {
            label: '1x1',
            value: '1x1',
          },
          {
            label: '2x1',
            value: '2x1',
          },
          {
            label: '2x2',
            value: '2x2',
          },
        ],
      },
      {
        prop: 'image',
        label: 'Image',
        type: 'resource',
        resourceType: 'contentful-asset',
      },
      {
        prop: 'slug',
        label: 'Slug',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['path'],
        },
      },
      {
        prop: 'label',
        label: 'Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'subLabel',
        label: 'Sub Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
    ],
  },
  {
    id: 'ActionCardBackdrop',
    label: 'Action Card Backdrop',
    type: 'card',
    schema: [
      {
        prop: 'image',
        label: 'Image',
        type: 'resource',
        resourceType: 'contentful-asset',
      },
      {
        prop: 'label',
        label: 'Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'slug',
        label: 'Slug',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['path'],
        },
      },
      {
        prop: 'subLabel',
        label: 'Sub Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'backgroundColor',
        label: 'Background Color',
        type: 'string',
        defaultValue: '#f8f9fa !important',
      },
      {
        prop: 'height',
        label: 'Height',
        type: 'string',
        defaultValue: '200px',
      },
    ],
  },
  {
    id: 'ActionHorizontalCard',
    label: 'Action Horizontal Card',
    type: 'card',
    schema: [
      {
        prop: 'align',
        label: 'Align',
        type: 'select',
        options: [
          {
            label: 'Left',
            value: 'left',
          },
          {
            label: 'Right',
            value: 'right',
          },
        ],
      },
      {
        prop: 'image',
        label: 'Image',
        type: 'resource',
        resourceType: 'contentful-asset',
      },
      {
        prop: 'slug',
        label: 'Slug',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['path'],
        },
      },
      {
        prop: 'label',
        label: 'Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'subLabel',
        label: 'Sub Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
    ],
  },
  {
    id: 'BannerCTA',
    label: 'Banner CTA',
    type: 'section',
    previewImage: '/assets/shopstory/banner-cta.png',
    schema: [
      {
        prop: 'image',
        label: 'Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'content',
        label: 'Content',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'BannerDuo',
    label: 'Banner Duo',
    type: 'section',
    previewImage: '/assets/shopstory/banner-duo.png',
    schema: [
      {
        prop: 'leftImage',
        label: 'Left Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'contentLeft',
        label: 'Content Left',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'rightImage',
        label: 'Right Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'contentRight',
        label: 'Content Right',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'ButtonsBanner',
    label: 'Buttons Banner',
    type: 'section',
    previewImage: '/assets/shopstory/buttons-banner.png',
    schema: [
      {
        prop: 'buttons',
        label: 'Buttons',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'CategoriesGrid',
    label: 'Categories Grid',
    type: 'section',
    previewImage: '/assets/shopstory/categories-grid.png',
    schema: [
      {
        prop: 'title',
        label: 'Title',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'categories',
        label: 'Categories',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'CardsNavigation',
    label: 'Card Navigation  - List (D)/ Carousel (M)',
    type: 'section',
    previewImage: '/assets/shopstory/trustpilot-reviews-v2.png',
    schema: [
      {
        prop: 'label',
        label: 'Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'cardType',
        label: 'Type of Card',
        type: 'select',
        options: ['ClearReview', 'ClearCard'],
      },
      {
        prop: 'list',
        label: 'List',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'CategoryListsSelector',
    label: 'Category Lists Selector',
    previewImage: '/assets/shopstory/card-lists-selector.png',
    type: 'section',
    schema: [
      {
        prop: 'title',
        label: 'Title',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'facets',
        label: 'Facets',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'CodeDiscount',
    label: 'Code Promo Applicator',
    type: 'section',
    schema: [
      {
        prop: 'code',
        label: 'Code Promo',
        type: 'string',
      },
      {
        prop: 'discountPercent',
        label: 'Pourcentage de réduction',
        type: 'string',
      },
      {
        prop: 'expirationTs',
        label: 'Expiration (en ts)',
        type: 'string',
      },
    ],
  },
  {
    id: 'FaqAccordion',
    label: 'FAQ Accordion',
    type: 'section',
    previewImage: '/assets/shopstory/faq-accordion.png',
    schema: [
      {
        prop: 'title',
        label: 'Title',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'list',
        label: 'List',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'FeaturedCardNavigation',
    label: 'FeaturedCardNavigation',
    previewImage: '/assets/shopstory/featured-card-navigation.png',
    type: 'section',
    schema: [
      {
        prop: 'title',
        label: 'Title',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'link',
        label: 'Link',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['link'],
        },
        defaultValue: undefined,
      },
      {
        prop: 'list',
        label: 'List',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'backgroundColor',
        label: 'Background Color',
        type: 'string',
      },
      {
        prop: 'padding',
        label: 'Padding',
        type: 'string',
      },
      {
        prop: 'color',
        label: 'Color',
        type: 'string',
      },
    ],
  },
  {
    id: 'FeaturedBlogArticlesNavigation',
    label: 'FeaturedBlogArticlesNavigation',
    previewImage: '/assets/shopstory/featured-blog-articles-navigation.png',
    type: 'section',
    schema: [
      {
        prop: 'label',
        label: 'Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'list',
        label: 'List',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'FeaturedCardCarousel',
    label: 'Featured Card Carousel',
    type: 'section',
    schema: [
      {
        prop: 'list',
        label: 'List',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'GridCards',
    label: 'Grid Cards',
    type: 'section',
    previewImage: '/assets/shopstory/grid-cards.png',
    schema: [
      {
        prop: 'label',
        label: 'Title',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'cards',
        label: 'Cards',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'HeroBannerHighlightCard',
    label: 'HeroBannerHighlightCard',
    type: 'section',
    previewImage: '/assets/shopstory/hero-banner-highlight-card.png',
    schema: [
      {
        prop: 'image',
        label: 'Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'desktopImage',
        label: 'Desktop Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'label',
        label: 'Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'subLabel',
        label: 'subLabel',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'button',
        label: 'Button',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['button'],
        },
      },
      {
        prop: 'isCardRight',
        label: 'Is Card Right',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['boolean'],
        },
      },
    ],
  },
  {
    id: 'HeroBannerHighlightCardSlider',
    label: 'HeroBannerHighlightCardSlider',
    type: 'section',
    schema: [
      {
        prop: 'list',
        label: 'List',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'isCardRight',
        label: 'Is Card Right',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['boolean'],
        },
      },
    ],
  },
  {
    id: 'HeroBanner',
    label: 'Hero Banner',
    type: 'section',
    previewImage: '/assets/shopstory/hero-banner.png',
    schema: [
      {
        prop: 'labels',
        label: 'Labels',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'image',
        label: 'Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'desktopImage',
        label: 'Desktop Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'buttons',
        label: 'Buttons',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'badges',
        label: 'Badges',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'HeroDuoBanner',
    label: 'Hero Duo Banner',
    type: 'section',
    previewImage: '/assets/shopstory/hero-duo-banner.png',
    schema: [
      {
        prop: 'labels',
        label: 'Labels',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'leftImage',
        label: 'Left Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'leftImageMobile',
        label: 'Left Image Mobile',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'rightImage',
        label: 'Right Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'rightImageMobile',
        label: 'Right Image Mobile',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'buttons',
        label: 'Buttons',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'badges',
        label: 'Badges',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'HeroDuoBannerV2',
    label: 'Hero Duo Banner V2',
    type: 'section',
    previewImage: '/assets/shopstory/hero-duo-banner-v2.png',
    schema: [
      {
        prop: 'labels',
        label: 'Labels',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'labelsMobile',
        label: 'Labels Mobile',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'leftImage',
        label: 'Left Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'rightImage',
        label: 'Right Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'imgMobile',
        label: 'Mobile Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'buttons',
        label: 'Buttons',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'txtPos',
        label: 'Text Position',
        type: 'select',
        options: [
          { value: 'left', label: 'Left' },
          { value: 'right', label: 'Right' },
        ],
      },
    ],
  },
  {
    id: 'HeroDuoCompare',
    label: 'Hero Duo Compare',
    type: 'section',
    previewImage: '/assets/shopstory/hero-duo-compare.png',
    schema: [
      {
        prop: 'leftImage',
        label: 'Left Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'leftImageMobile',
        label: 'Left Image Mobile',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'badgeLeft',
        label: 'Badge Left',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['badge'],
        },
      },
      {
        prop: 'rightImage',
        label: 'Right Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'rightImageMobile',
        label: 'Right Image Mobile',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'badgeRight',
        label: 'Badge Right',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['badge'],
        },
      },
    ],
  },
  {
    id: 'HeroSmallBordered',
    label: 'Hero Small (Bordered)',
    type: 'section',
    previewImage: '/assets/shopstory/small-hero.png',
    schema: [
      {
        prop: 'mobileImg',
        label: 'Mobile Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'img',
        label: 'Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
    ],
  },
  {
    id: 'HeroSlider',
    label: 'Hero Slider',
    type: 'section',
    previewImage: '/assets/shopstory/hero-slider.png',
    schema: [
      {
        prop: 'list',
        label: 'List',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'HeroVideo',
    label: 'Hero Video',
    type: 'section',
    schema: [
      {
        prop: 'badge',
        label: 'Top Badge',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['badge'],
        },
      },
      {
        prop: 'topLabel',
        label: 'Top Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'video',
        label: 'Video Desktop',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['video'],
        },
      },
      {
        prop: 'mobileVideo',
        label: 'Video Mobile',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['video'],
        },
      },
      {
        prop: 'bottomLabel',
        label: 'Bottom Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'button',
        label: 'Bottom Button',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['button'],
        },
      },
    ],
  },
  {
    id: 'HeroVideoSvg',
    label: 'Video Banner Svg',
    previewImage: '/assets/shopstory/hero-video-svg.png',
    type: 'section',
    schema: [
      {
        prop: 'mobileSvg',
        label: 'Mobile SVG',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'svg',
        label: 'SVG',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'mobileVideo',
        label: 'Mobile Video',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['video'],
        },
      },
      {
        prop: 'video',
        label: 'Video',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['video'],
        },
      },
      {
        prop: 'link',
        label: 'Link',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['link'],
        },
      },
    ],
  },
  {
    id: 'ImageBanner',
    label: 'Image Banner',
    type: 'section',
    schema: [
      {
        prop: 'image',
        label: 'Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
    ],
  },
  {
    id: 'HeroTextBanner',
    label: 'Hero Text Banner',
    type: 'section',
    schema: [
      {
        prop: 'title',
        label: 'Title',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'subTitle',
        label: 'Sub Title',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
    ],
  },
  {
    id: 'IntroCardNavigation',
    label: 'IntroCardNavigation',
    type: 'section',
    schema: [
      {
        prop: 'title',
        label: 'Title',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'subLabel',
        label: 'Sub Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'link',
        label: 'Link',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['link'],
        },
        defaultValue: undefined,
      },
      {
        prop: 'list',
        label: 'List',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'backgroundColor',
        label: 'Background Color',
        type: 'string',
      },
      {
        prop: 'padding',
        label: 'Padding',
        type: 'string',
      },
      {
        prop: 'color',
        label: 'Color',
        type: 'string',
      },
    ],
  },
  {
    id: 'Label',
    label: 'Label',
    type: 'section',
    schema: [
      {
        prop: 'label',
        label: 'label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
    ],
  },

  {
    id: 'LabelItem',
    label: 'Label',
    type: 'item',
    schema: [
      {
        prop: 'label',
        label: 'label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
    ],
  },

  {
    id: 'ImageItem',
    label: 'Image',
    type: 'item',
    schema: [
      {
        prop: 'image',
        label: 'image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
    ],
  },

  {
    id: 'LineItemGrid',
    label: 'Grid Line ShopItem',
    type: 'section',
    previewImage: '/assets/shopstory/grid-line_item.png',
    schema: [
      {
        prop: 'facets',
        label: 'ShopItems (Facets)',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'type',
        label: 'Shop Item Type',
        type: 'select',
        defaultValue: 'default',
        options: [
          { value: 'default', label: 'Default' },
          { value: 'simple', label: 'Simple' },
          { value: 'new', label: 'New' },
        ],
      },
    ],
  },
  {
    id: 'LineItemLookBook',
    label: 'LookBook Line ShopItem',
    type: 'section',
    previewImage: '/assets/shopstory/look_book-line_item.png',
    schema: [
      {
        prop: 'labels',
        label: 'Labels',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'image',
        label: 'Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'button',
        label: 'Button',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['button'],
        },
      },
      {
        prop: 'facets',
        label: 'ShopItems (Facets)',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'imagePosition',
        label: 'Image Position',
        type: 'select',
        options: [
          { value: 'left', label: 'Left' },
          { value: 'right', label: 'Right' },
        ],
      },
    ],
  },

  {
    id: 'ProductExplorer',
    label: 'Product Explorer',
    type: 'section',
    previewImage: '/assets/shopstory/product-explorer.png',
    schema: [
      {
        prop: 'label',
        label: 'Title',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'subLabel',
        label: 'Sub-Title',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'productList',
        label: 'Product List',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'ProductCardList',
    label: 'ProductCardList',
    type: 'section',
    schema: [
      {
        prop: 'label',
        label: 'Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'marketingItems',
        label: 'Marketing Items',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'facets',
        label: 'Facets',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'ProductCardListCarousel',
    label: 'ProductCardListCarousel',
    type: 'section',
    previewImage: '/assets/shopstory/product-card-list-carousel.png',
    schema: [
      {
        prop: 'label',
        label: 'Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'facets',
        label: 'Facets',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'Quotation',
    label: 'Quotation',
    previewImage: '/assets/shopstory/quotation.png',
    type: 'section',
    schema: [
      {
        prop: 'body',
        label: 'Body',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['paragraph'],
        },
      },
      {
        prop: 'quoteColor',
        label: 'Quote Color',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['color'],
        },
      },
    ],
  },
  {
    id: 'Reassurance',
    label: 'Reassurance',
    type: 'section',
    previewImage: '/assets/shopstory/reassurance.png',
    schema: [
      {
        prop: 'list',
        label: 'List',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'RetailBooking',
    label: 'Retail Booking',
    type: 'section',
    schema: [],
  },
  {
    id: 'RetailBookingCancel',
    label: 'Retail Cancel Booking',
    type: 'section',
    schema: [],
  },
  {
    id: 'RetailInfo',
    label: 'Retail Info',
    type: 'section',
    previewImage: '/assets/shopstory/retail-info.png',
    schema: [
      {
        prop: 'mainInfo',
        label: 'Main Info',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'openingInfo',
        label: 'Opening Info',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'contactInfo',
        label: 'Contact Info',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'reviewInfo',
        label: 'Review Info',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'RetailPricing',
    label: 'Retail Pricing',
    type: 'section',
    previewImage: '/assets/shopstory/retail-pricing.png',
    schema: [
      {
        prop: 'label',
        label: 'Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'subLabel',
        label: 'subLabel',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'pricing',
        label: 'Product Pricing',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'button',
        label: 'Button',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['button'],
        },
      },
    ],
  },
  {
    id: 'SeoRichBody',
    label: 'Seo Rich Body',
    type: 'section',
    previewImage: '/assets/shopstory/seo-rich-body.png',
    schema: [
      {
        prop: 'list',
        label: 'List',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'title',
        label: 'Title',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
    ],
  },
  {
    id: 'ShareSection',
    label: 'Whatsapp Share Section',
    type: 'section',
    previewImage: '/assets/shopstory/share-section.png',
    schema: [
      {
        prop: 'background',
        label: 'Background Color',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['color'],
        },
      },
      {
        prop: 'text',
        label: 'Text',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['paragraph'],
        },
      },
      {
        prop: 'button',
        label: 'Button',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['button'],
        },
      },
      {
        prop: 'emailButton',
        label: 'Button Email',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
    ],
  },
  {
    id: 'Title',
    label: 'Title',
    type: 'item',
    schema: [
      {
        prop: 'children',
        label: 'text',
        type: 'string',
      },
      {
        prop: 'size',
        label: 'Size',
        type: 'number',
        defaultValue: 40,
      },
      {
        prop: 'align',
        label: 'Align',
        type: 'select',
        options: [
          {
            label: 'Left',
            value: 'start',
          },
          {
            label: 'Center',
            value: 'center',
          },
          {
            label: 'Right',
            value: 'end',
          },
        ],
      },
      {
        prop: 'italic',
        label: 'Italic',
        type: 'boolean',
      },
      {
        prop: 'strikethrough',
        label: 'Strikethrough',
        type: 'boolean',
      },
      {
        prop: 'truncate',
        label: 'Truncate',
        type: 'boolean',
      },
      {
        prop: 'weight',
        label: 'Weight',
        type: 'select',
        options: [
          {
            label: 'Normal',
            value: 'normal',
          },
          {
            label: 'Bold',
            value: 'bold',
          },
          {
            label: 'Light',
            value: 'light',
          },
        ],
      },
      {
        prop: 'transform',
        label: 'Transform',
        type: 'select',
        options: [
          {
            label: 'None',
            value: 'none',
          },
          {
            label: 'Uppercase',
            value: 'uppercase',
          },
          {
            label: 'Lowercase',
            value: 'lowercase',
          },
          {
            label: 'Capitalize',
            value: 'capitalize',
          },
          {
            label: 'Full Width',
            value: 'full-width',
          },
        ],
      },
    ],
  },
  {
    id: 'TrustpilotReviews',
    label: 'TrustpilotReviews',
    type: 'section',
    previewImage: '/assets/shopstory/trustpilot-reviews.png',
    schema: [
      {
        prop: 'element',
        label: 'Main Element',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'reviews',
        label: 'Reviews',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'readReview',
        label: '`Lire review` Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
    ],
  },
  {
    id: 'CollectionCards',
    label: 'Collection Cards',
    type: 'section',
    previewImage: '/assets/shopstory/collection-cards.png',
    schema: [
      {
        prop: 'label',
        label: 'Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'subLabel',
        label: 'Sub Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'collections',
        label: 'Collections',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['block'],
        },
      },
      {
        prop: 'color',
        label: 'Color',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['color'],
        },
      },
    ],
  },
  {
    id: 'UspCard',
    label: 'Usp Card',
    type: 'card',
    schema: [
      {
        prop: 'image',
        label: 'Image',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['image'],
        },
      },
      {
        prop: 'slug',
        label: 'Slug',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['path'],
        },
      },
      {
        prop: 'label',
        label: 'Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'subLabel',
        label: 'Sub Label',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['label'],
        },
      },
      {
        prop: 'height',
        label: 'Height',
        type: 'string',
        defaultValue: '200px',
      },
    ],
  },
  {
    id: 'VideoBanner',
    label: 'Video Banner',
    type: 'section',
    schema: [
      {
        prop: 'video',
        label: 'Video',
        type: 'resource',
        resourceType: 'contentful-entry',
        params: {
          contentTypeId: ['video'],
        },
      },
    ],
  },
].sort((a, b) => a.id.localeCompare(b.id)) as any;

export const shopstoryConfig: Config = {
  plugins: [
    contentfulPlugin({
      space: process.env.NEXT_PUBLIC_CF_SPACE_ID as string,
      environment: process.env.NEXT_PUBLIC_CF_ENVIRONMENT as string,
      accessToken: process.env.NEXT_PUBLIC_CF_DELIVERY_ACCESS_TOKEN as string,
      previewAccessToken: process.env
        .NEXT_PUBLIC_CF_DELIVERY_PREVIEW_TOKEN as string,
    }),
  ],
  resourceTypes: {
    asset: {
      fetch: fetchContentfulAsset,
      widget: {
        type: 'item-picker',
        getItems: fetchAssetGetItems,
        getItemById: fetchAssetById,
      },
    },
  },
  buttons: [
    {
      id: 'Button',
      label: 'Button',
      schema: [
        {
          prop: 'item',
          label: 'Item',
          type: 'resource',
          resourceType: 'contentful-entry',
          params: {
            contentTypeId: ['button'],
          },
        },
      ],
    },
  ],
  components,
  space: [
    {
      id: 'containerMargin.default',
      value: '30px',
    },
  ],
  colors: [
    {
      id: 'dark-blue',
      label: 'Dark blue',
      value: '#09034A',
      mapTo: ['$backgroundDark'],
    },
  ],

  devices: {
    xs: {
      w: 428,
      h: 926,
    },
    lg: {
      hidden: false,
    },
    xl: {
      startsFrom: 1200,
    },
  },

  // fonts: [
  //   {
  //     id: 'body',
  //     label: 'Body',
  //     value: {
  //       fontFamily: 'Gilmer',
  //     },
  //     mapTo: '$body',
  //   },
  // ],
};
