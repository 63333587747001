import useCommerceContext from '@/utils/context/commerce';
import { useCallback } from 'react';
import DataLayerHelper from '@/helpers/Datalayer';
import { ICartItem, ICartLine } from '@/interface.custom';
import ShopifyBuy, { CheckoutLineItemInput, AttributeInput } from 'shopify-buy';
import usePageContext from '@/utils/context/page';
import { fetchShopifyCart } from '@/utils/services/ShopstoryStorefrontService';
import NoozNextApi, { NOOZ_NEXT_REGISTRY } from '@/utils/services/NoozNextApi';
import LocaleHelper from '@/helpers/Locale';
import { useToggleCartDrawer } from '@/utils/context/drawer';
import { APP_INFO } from '@/utils';
import useStaticContent from '@/utils/hook/useStaticContent';
import { useRouter } from 'next/router';

const useCartActions = () => {
  const { locale, geoMarket } = usePageContext();
  const { client, cart, setCart, freeVariant } = useCommerceContext();
  const toggleCartDrawer = useToggleCartDrawer(true);
  const staticContent = useStaticContent();
  const router = useRouter();

  const updateFromCart = useCallback(
    async (
      items: ICartLine[],
      modificationAmount: number,
      _?: {
        action?: 'notify' | 'redirect' | 'none' | 'drawer';
        item?: any;
      },
    ) => {
      if (modificationAmount === 0) return;
      if (cart == null || client == null) {
        throw new Error('[updateFromCart] Called addItemsToCart too soon');
      }
      if (items.length < 1) {
        throw new Error(
          'Must include at least one line item, empty line items found',
        );
      }

      const lineItemsPatch = items.map((item) => ({
        quantity: item.quantity + modificationAmount,
        id: item.id,
      }));

      let newCart: ShopifyBuy.Checkout | null =
        await client.checkout.updateLineItems(
          cart.id,
          // @ts-ignore
          lineItemsPatch,
        );

      DataLayerHelper.addToCartEvent(
        items.map((cl) => ({
          quantity: modificationAmount,
          type: cl.__itemType,
          dataLayerItem: cl.__dataLayerItem,
          contentfulItem: cl.__contentfulItem,
          shopifyVariant: cl.variant,
        })),
      );
      await fetchShopifyCart(
        {
          ...geoMarket,
          name: LocaleHelper.getValidShopifyCountry(locale as string),
        },
        newCart.id,
      )
        .then(async (res) => {
          if (
            freeVariant &&
            res &&
            !res.lineItems.find(
              (li) => li?.variant?.id === freeVariant.variantId,
            )
          ) {
            await client.checkout.addLineItems(res.id, [freeVariant]);
            return fetchShopifyCart(
              {
                ...geoMarket,
                name: LocaleHelper.getValidShopifyCountry(locale as string),
              },
              res.id,
            );
          } else if (
            freeVariant &&
            res &&
            res.lineItems.length === 1 &&
            !!res.lineItems.find(
              (li) => li?.variant?.id === freeVariant.variantId,
            )
          ) {
            await client.checkout.removeLineItems(res.id, [
              res.lineItems[0].id,
            ]);

            return fetchShopifyCart(
              {
                ...geoMarket,
                name: LocaleHelper.getValidShopifyCountry(locale as string),
              },
              res.id,
            );
          } else {
            return res;
          }
        })
        .then((res) => {
          if (res) setCart(res);
          if (APP_INFO.type === 'B2B') {
            return router.push(`/${locale}/${staticContent.cartlink}`);
          } else {
            toggleCartDrawer();
          }
        });
    },
    [client, cart, freeVariant, toggleCartDrawer, router, staticContent],
  );

  const addToCart = useCallback(
    async (
      items: ICartItem[],
      customAttributes?: AttributeInput[],
      action?: 'notify' | 'redirect' | 'none' | 'drawer',
    ) => {
      if (cart == null || client == null) {
        throw new Error('[addToCart] Called addItemsToCart too soon');
      }

      if (items.length < 1) {
        throw new Error(
          'Must include at least one line item, empty line items found',
        );
      }
      const lineItems: { toAdd: ICartItem[]; toUpdate: ICartLine[] } = {
        toAdd: [],
        toUpdate: [],
      };

      items.forEach((item) => {
        const lineItem = cart.lineItems.find(
          (li) => li.variant?.id === item.shopifyVariant.id,
        );

        const lineItemCAs = lineItem
          ? Object.values(lineItem.customAttributes).reduce(
              (prev: Record<string, any>, attr) => {
                if (attr && attr.key && attr.value) {
                  prev[attr.key] = attr.value;
                  try {
                    prev[attr.key] = JSON.parse(prev[attr.key]);
                  } catch (e) {}
                }
                return prev;
              },
              {},
            )
          : undefined;

        if (
          lineItem &&
          lineItemCAs?.__itemType === 'single' &&
          customAttributes?.find((c: AttributeInput) => c.key === '__itemType')
            ?.value === 'single'
        ) {
          const cartLine = {
            ...lineItem,
            lineItems: [lineItem],
            ...lineItemCAs,
          } as ICartLine;
          lineItems.toUpdate.push(cartLine);
        } else {
          lineItems.toAdd.push(item);
        }
      });

      if (lineItems.toUpdate.length > 0) {
        await updateFromCart(lineItems.toUpdate, 1, {
          action,
          item: items?.[0],
        });
      }
      if (lineItems.toAdd.length > 0) {
        let lineItemsPatch: CheckoutLineItemInput[] = lineItems.toAdd.map(
          (item) => {
            return JSON.parse(
              JSON.stringify({
                quantity: item.quantity,
                variantId: item.shopifyVariant.id,
                customAttributes: [
                  {
                    key: '__contentfulItem',
                    value: JSON.stringify(item.contentfulItem),
                  },
                  {
                    key: '__dataLayerItem',
                    value: JSON.stringify(item.dataLayerItem),
                  },
                  ...(customAttributes || []),
                ],
              }),
            );
          },
        );
        if (lineItemsPatch?.[0]) {
          // const _li = lineItemsPatch?.[0];
          // if (li?.customAttributes) {
          //   const pos = li.customAttributes.findIndex(
          //     ({ key, value }) => key === '__itemType' && value === 'pack',
          //   );
          //   if (pos >= 0) li.customAttributes[pos].value = 'packParent';
          // }
        }

        client.checkout
          .addLineItems(cart.id, lineItemsPatch)
          .then((newCart) => {
            DataLayerHelper.addToCartEvent(items);

            return fetchShopifyCart(
              {
                ...geoMarket,
                name: LocaleHelper.getValidShopifyCountry(locale as string),
              },
              newCart.id,
            );
          })
          .then(async (res) => {
            NoozNextApi.instance({
              ...NOOZ_NEXT_REGISTRY.LOGS.PACK,
              data: {
                id: customAttributes?.find((c) => c.key === '__itemId')?.value,
                data: {
                  res,
                  lineItemsPatch,
                },
              },
            });
            if (
              freeVariant &&
              res &&
              !res.lineItems.find(
                (li) => li?.variant?.id === freeVariant.variantId,
              )
            ) {
              await client.checkout.addLineItems(res.id, [freeVariant]);

              return fetchShopifyCart(
                {
                  ...geoMarket,
                  name: LocaleHelper.getValidShopifyCountry(locale as string),
                },
                res.id,
              );
            } else {
              return res;
            }
          })
          .then((res) => {
            if (res) setCart(res);
            if (APP_INFO.type === 'B2B') {
              return router.push(`/${locale}/${staticContent.cartlink}`);
            } else {
              toggleCartDrawer();
            }
          })
          .catch((e) => {
            NoozNextApi.instance({
              ...NOOZ_NEXT_REGISTRY.LOGS.PACK,
              data: {
                id: customAttributes?.find((c) => c.key === '__itemId')?.value,
                error: true,
                data: {
                  lineItemsPatch,
                },
              },
            });
            console.error('=> ', e);
          });
      }
    },
    [
      client,
      cart,
      updateFromCart,
      freeVariant,
      toggleCartDrawer,
      router,
      staticContent,
    ],
  );

  const removeFromCart = useCallback(
    async (items: ICartLine[]) => {
      if (cart == null || client == null) {
        throw new Error('[removeFromCart] Called addItemsToCart too soon');
      }

      if (items.length < 1) {
        throw new Error(
          'Must include at least one line item, empty line items found',
        );
      }

      DataLayerHelper.removeFromCartEvent(items);
      let newCart: ShopifyBuy.Checkout = await client.checkout.removeLineItems(
        cart.id,
        items.map(({ id }) => id),
      );

      const updateCart: ShopifyBuy.Checkout | null = await new Promise(
        (resolve) => {
          fetchShopifyCart(
            {
              ...geoMarket,
              name: LocaleHelper.getValidShopifyCountry(locale as string),
            },
            newCart.id,
          )
            .then(async (res) => {
              if (
                freeVariant &&
                res &&
                res.lineItems.length === 1 &&
                !!res.lineItems.find(
                  (li) => li?.variant?.id === freeVariant.variantId,
                )
              ) {
                await client.checkout.removeLineItems(res.id, [
                  res.lineItems[0].id,
                ]);

                return fetchShopifyCart(
                  {
                    ...geoMarket,
                    name: LocaleHelper.getValidShopifyCountry(locale as string),
                  },
                  res.id,
                );
              } else {
                return res;
              }
            })
            .then((res) => {
              resolve(res || null);
            });
        },
      );

      setCart(updateCart);
    },
    [client, cart, freeVariant],
  );

  return { addToCart, removeFromCart, updateFromCart };
};

export default useCartActions;
