import React, { useMemo } from 'react';
import { Breadcrumbs } from '@mantine/core';
import TextLink from '../Link/TextLink';
import { IBreadcrumb } from '@/interface.custom';
import usePageContext from '@/utils/context/page';
import { validateEntry } from '@/helpers/SchemaOrgJsonLd';

interface BreadcrumbsWrapperProps {
  list: IBreadcrumb[];
  color?: string;
}

const BreadcrumbsWrapper = ({ list, color }: BreadcrumbsWrapperProps) => {
  const { locale } = usePageContext();
  const jsonLdData = useMemo(() => {
    if (list && list.length > 0) {
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: list.map((link, key) => ({
          '@type': 'ListItem',
          position: key + 1,
          name: validateEntry(link.fields.label?.fields.text),
          item:
            typeof link.fields.slug?.fields.url === 'string'
              ? `${(process.env.NEXT_PUBLIC_DOMAIN || '').replace(
                  /\/$/,
                  '',
                )}/${locale}/${(link.fields.slug?.fields.url || '').replace(
                  /^\//,
                  '',
                )}`
              : '',
        })),
      };
    }
    return undefined;
  }, [list]);

  return (
    <>
      <Breadcrumbs sx={{ flexWrap: 'wrap' }}>
        {list?.map((item, key) => {
          return (
            <TextLink
              key={key}
              color={color}
              text={item.fields.label?.fields?.text as string}
              url={item.fields.slug?.fields?.url as string}
              size={14}
              weight={key === list.length - 1 ? '600' : undefined}
              type={'animated-underline'}
            />
          );
        })}
      </Breadcrumbs>
      {jsonLdData ? (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData) }}
        />
      ) : null}
    </>
  );
};

export { BreadcrumbsWrapper as default };
