export const checkoutCreateQuery = (variables: Record<string, any>) => ({
  query: `
mutation checkoutCreate($input: CheckoutCreateInput!, $country: CountryCode!)
@inContext(country: $country) {
  checkoutCreate(input: $input) {
    checkout {
      id
      currencyCode
      webUrl
      __typename
      lineItems(first: 250) {
        edges {
          cursor
          node {
            id
            quantity
            customAttributes {
              key
              value
            }
            title
            variant {
              id
              price {
                amount
                currencyCode
              }
              compareAtPrice {
                amount
                currencyCode
              }
            }
          }
        }
      }
      subtotalPrice {
        amount
        currencyCode
      }
      discountApplications(first: 50){
        edges {
          node {
            allocationMethod
            targetSelection
            targetType
            ... on AutomaticDiscountApplication {
              title
            }
            ... on ScriptDiscountApplication {
              title
            }
            ... on ManualDiscountApplication {
              title
            }
            __typename
            value {
              ... on MoneyV2 {
                amount
                currencyCode
              }
              ... on PricingPercentageValue {
                percentage
              }
            }
          }
        }
      }
      lineItemsSubtotalPrice {
        amount
        currencyCode
      }
      totalPrice {
        amount
        currencyCode
      }
      buyerIdentity {
        countryCode
      }
    }
  }
}

`,
  variables,
});

export const fetchCartQuery = (variables: Record<string, any>) => ({
  query: `
query fetchCart($id: ID!, $country: CountryCode!)
@inContext(country: $country) {
  node(id: $id) {
    ... on Checkout {
      id
      currencyCode
      createdAt
      completedAt
      webUrl
      __typename
      lineItems(first: 250) {
        edges {
          cursor
          node {
            id
            quantity
            discountAllocations {
              allocatedAmount {
                amount
              }
            }
            customAttributes {
              key
              value
            }
            title
            variant {
              id
              price {
                amount
                currencyCode
              }
              compareAtPrice {
                amount
                currencyCode
              }
            }
          }
        }
      }
      discountApplications(first: 10) {
        edges {
          node {
            allocationMethod
            targetSelection
            targetType
            ... on AutomaticDiscountApplication {
              title
            }
            ... on ScriptDiscountApplication {
              title
            }
            ... on ManualDiscountApplication {
              title
            }
            ... on DiscountCodeApplication {
              code
            }
            __typename
            value {
              ... on MoneyV2 {
                amount
                currencyCode
              }
              ... on PricingPercentageValue {
                percentage
              }
            }
          }
        }
      }
      subtotalPrice {
        amount
        currencyCode
      }
      lineItemsSubtotalPrice {
        amount
        currencyCode
      }
      totalPrice {
        amount
        currencyCode
      }
      buyerIdentity {
        countryCode
      }
    }
  }
}
`,
  variables,
});

export const getProductsByQuery = (variables: Record<string, any>) => ({
  query: `
query getProductsByQuery(
  $id: ID!
  $countryCode: CountryCode!
  $warehouseId: ID!
) {
  product(id: $id) {
    id
    title
    options {
      name
      values
    }
    variants(first: 100) {
      edges {
        node {
          id
          availableForSale
          sku
          title
          inventoryPolicy
          inventoryItem {
            inventoryLevel(locationId: $warehouseId) {
              quantities(names: ["available"]) { name quantity }
            }
          }
          contextualPricing(context: { country: $countryCode }) {
            price {
              amount
              currencyCode
            }
            compareAtPrice {
              amount
              currencyCode
            }
          }
          selectedOptions {
            name
            value
          }
        }
      }
    }
  }
}
`,
  variables,
});

export const getVariantStockQuery = (variables: Record<string, any>) => ({
  query: `
query getVariantStock($id: ID!, $warehouseId: ID!) {
  productVariant(id: $id) {
    id
    sku
    inventoryPolicy
    inventoryItem {
      inventoryLevel(locationId: $warehouseId) {
        quantities(names: ["available"]){name quantity}
      }
    }
  }
}

`,
  variables,
});

export const getProductStockQuery = (variables: Record<string, any>) => ({
  query: `
query getProductStock($id: ID!, $warehouseId: ID!) {
  product(id: $id) {
    id
    variants(first: 100) {
      edges {
        node {
          id
          inventoryItem {
            inventoryLevel(locationId: $warehouseId) {
              quantities(names: ["available"]) { name quantity }
            }
          }
        }
      }
    }
  }
}

`,
  variables,
});
