import React, { useEffect, useState } from 'react';
import { Flex, createStyles, useMantineTheme } from '@mantine/core';
import Currency from '@/helpers/Currency';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import Cookie from '@/helpers/Cookie';
import { APPLY_DISCOUNT_PRODUCT_FACET } from '@/templates/ProductCategory';
import usePageContext from '@/utils/context/page';
import { isWindowDefined } from 'swr/_internal';
import { APP_INFO } from '@/utils';
import useAuth from '@/utils/context/authentication';
import { ShopItemVariant } from '@/@types/shopitem';

const useStyles = createStyles((theme, { isNew }: { isNew?: boolean }) => ({
  wrapper: {
    flexDirection: 'column',
    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'row',
    },
  },
  text: {
    fontSize: 18,
    fontWeight: 500,
  },
}));

interface IProductCardPrice {
  isNew?: boolean;
  item: ShopItemVariant;
}
const ProductCardPrice = ({ item, isNew }: IProductCardPrice) => {
  if (!item.price) console.warn('[item without price]=>', item);
  const { classes } = useStyles({ isNew });
  const auth = useAuth();
  const theme = useMantineTheme();
  // @ts-ignore
  const { page, components } = usePageContext();
  const [prices, setPrices] = useState<{ display?: string; compare?: string }>({
    display: APP_INFO.type === 'B2B' ? undefined : item.price?.amount,
    compare: APP_INFO.type === 'B2B' ? undefined : item.compareAtPrice?.amount,
  });
  const [currencyCode, setCurrencyCode] = useState(item.price?.currencyCode);
  useEffect(() => {
    if (APP_INFO.type === 'B2B') {
      if (auth.isAuthenticated && auth.catalogB2B) {
        let sku = item.title;
        if (/(-DEG-)|(-REA-)/.test(sku)) sku = `${sku}-P-100`;
        const prices = auth.catalogB2B[sku];
        if (prices) {
          setCurrencyCode(prices.price?.currencyCode || 'EUR');
          setPrices({
            display: prices.price?.amount,
            compare: prices.compareAtPrice
              ? prices.compareAtPrice.amount
              : undefined,
          });
        }
      }
    } else {
      const tmp: { display: string; compare?: string } = {
        display: item.price?.amount,
        compare: item.compareAtPrice?.amount,
      };
      if (isWindowDefined) {
        // @ts-ignore
        if (Cookie.get(APPLY_DISCOUNT_PRODUCT_FACET)) {
          const discount = parseFloat(
            Cookie.get(APPLY_DISCOUNT_PRODUCT_FACET) as unknown as string,
          );

          if (!tmp.compare) tmp.compare = tmp.display;
          tmp.display = (
            parseFloat(tmp.display) -
            (parseFloat(tmp.display) * discount) / 100
          ).toFixed(2);
        }
        setPrices(tmp);
      }
    }
  }, [item, page, components, auth]);

  return !prices.display ? null : (
    <Flex
      className={classes.wrapper}
      gap={6}>
      <NoozText
        sx={{ whiteSpace: 'nowrap' }}
        className={classes.text}>
        <NoozText
          className={classes.text}
          color={prices.compare && theme.colors.red[6]}
          weight={prices.compare ? 500 : 600}
          component='span'>
          {`${Currency.toFormattedPrice(prices.display)}`}
          {` `}
        </NoozText>
        <NoozText
          className={classes.text}
          color={prices.compare && theme.colors.red[6]}
          component='span'>
          {`${Currency.toSymbol(currencyCode)}`}
        </NoozText>
      </NoozText>
      {prices.compare && (
        <NoozText
          sx={{ whiteSpace: 'nowrap' }}
          className={classes.text}>
          <NoozText
            className={classes.text}
            strikethrough
            component='span'>
            {`${Currency.toFormattedPrice(prices.compare)}`}
          </NoozText>
          {` `}
          <NoozText
            className={classes.text}
            component='span'>
            {`${Currency.toSymbol(currencyCode)}`}
          </NoozText>
        </NoozText>
      )}
    </Flex>
  );
};

export default ProductCardPrice;
