import { CommerceProvider } from '@/utils/context/commerce';
import { AuthenticationProvider } from '@/utils/context/authentication';
import { DialogProvider, DrawerProvider, ModalProvider } from '@/utils/context';
import React from 'react';
import { CountryProvider } from '@/utils/context/country';
import { PageContextProvider } from '@/utils/context/page';
import theme from '@/helpers/Theme';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { CookieBannerProvider } from '@/utils/context/cookieBanner';
import { MainPopupModalProvider } from '../Popup/MainPopupModal';
import { TeaserPopupDrawerProvider } from '../Popup/TeaserPopupDrawer';
import LastViewed from '@/components/LastViewed/LastViewed';

const AppProvider = ({
  children,
  pageProps,
}: {
  children: JSX.Element | JSX.Element[];
  pageProps: any;
}) => {
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={theme}>
      <AuthenticationProvider>
        <PageContextProvider value={pageProps}>
          <LastViewed>
            <CountryProvider locale={pageProps.locale}>
              <CookieBannerProvider>
                <MainPopupModalProvider>
                  <TeaserPopupDrawerProvider>
                    <CommerceProvider>
                      <DrawerProvider>
                        <DialogProvider>
                          <ModalProvider>
                            <Notifications />
                            {children}
                          </ModalProvider>
                        </DialogProvider>
                      </DrawerProvider>
                    </CommerceProvider>
                  </TeaserPopupDrawerProvider>
                </MainPopupModalProvider>
              </CookieBannerProvider>
            </CountryProvider>
          </LastViewed>
        </PageContextProvider>
      </AuthenticationProvider>
    </MantineProvider>
  );
};

export default AppProvider;
