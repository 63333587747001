import React, { useContext } from 'react';
import { ActionIcon, createStyles, Indicator, MediaQuery } from '@mantine/core';
import Search from '@/components/Search/Search';
import { SearchDrawerContext } from '@/components/Search/SearchDrawer/SearchDrawer';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import {
  DISABLED_VIEWED_ITEMS,
  useLastViewed,
} from '@/components/LastViewed/LastViewed';
import useMount from '@/utils/hook/useMount';
import Datalayer from '@/helpers/Datalayer';
import CartAction from '@/components/Header/HeaderAction/CartAction';
import { APP_INFO } from '@/utils';
import B2BCartAction from '@/components/Header/HeaderAction/B2BCartAction';

export const useStyles = createStyles(() => {
  return {
    button: {
      color: 'inherit',
      transition: 'color 0.1s ease-in-out',
    },
    link: {
      color: 'inherit',
    },
  };
});

const HeaderAction = ({}: { identifier: string }) => {
  const lastViewed = useLastViewed();
  const hasMount = useMount();
  const searchDrawerState = useContext(SearchDrawerContext);
  const isSearchActive = searchDrawerState[0].opened;

  return (
    <>
      {hasMount &&
      lastViewed.quantity > 0 &&
      !DISABLED_VIEWED_ITEMS &&
      !isSearchActive ? (
        <MediaQuery
          largerThan='xs'
          styles={{ display: 'none' }}>
          <ActionIcon
            radius='xl'
            size='lg'
            variant='subtle'
            color='black'
            onClick={() => {
              Datalayer.openViewedItems();
              lastViewed.setVisible((prev) => !prev);
            }}
            // className={classes.button}
          >
            <Indicator
              label={lastViewed.quantity}
              size={20}
              color={'text'}
              styles={{
                root: {
                  display: 'flex',
                },
              }}>
              <NoozIcon
                iconKey={'History'}
                strokeWidth={1.3}
                size={24}
              />
            </Indicator>
          </ActionIcon>
        </MediaQuery>
      ) : null}
      {/* TODO ACTIVE/INACTIVE SEARCH HEADER */}
      <Search />

      {/*{!isSearchActive && (*/}
      {/*  <MediaQuery*/}
      {/*    largerThan='md'*/}
      {/*    styles={{ display: 'none' }}>*/}
      {/*    <CartAction />*/}
      {/*  </MediaQuery>*/}
      {/*)}*/}

      {APP_INFO.type === 'B2B' ? <B2BCartAction /> : <CartAction />}
    </>
  );
};

export default HeaderAction;
