import { useMemo } from 'react';
import usePageContext from '@/utils/context/page';
import { IFacet } from '@/@types/generated/contentful';
import { LocalizedSlug } from '@/utils/utils';

const useLocalizedSlug: LocalizedSlug = (
  href: string,
  query: any = {},
  facet: IFacet[] = [],
) => {
  const { locale } = usePageContext();

  const facetQuery = facet.reduce(
    (result, f) => ({
      ...result,
      [f.fields.attribute as string]: f.fields.value,
      // [f.fields.attribute?.toLowerCase() as string]: f.fields.value,
    }),
    {},
  );

  const pathname = useMemo(
    () =>
      (`/${locale || ''}/${href?.replace(`/${locale || ''}`, '') || ''}` || '')
        .replace(/\/\//g, '/')
        .split('?')[0],
    [locale, href],
  );
  const finalQuery = useMemo(() => {
    const q = { ...query, ...facetQuery };

    const params = href?.split('?')?.[1];
    if (params) {
      const sp = new URLSearchParams(params).entries();
      // @ts-ignore
      for (const [key, value] of sp) {
        q[key] = value;
      }
    }
    return q;
  }, [href, query, facetQuery]);

  return { pathname, query: finalQuery };
};

export default useLocalizedSlug;
