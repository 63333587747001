import React, { createContext, useContext, useEffect, useReducer } from 'react';
import NoozNextApi, { NOOZ_NEXT_REGISTRY } from '@/utils/services/NoozNextApi';
import { useRouter } from 'next/router';

export const AuthContext = createContext<IAuthenticationContext>({
  isLoading: true,
});
const SetAuthContext = createContext<React.Dispatch<IAction>>(
  null as unknown as React.Dispatch<IAction>,
);

const useAuth = (): IAuthenticationContext => {
  return useContext(AuthContext);
};

export const useSetAuth = (): React.Dispatch<IAction> => {
  return useContext(SetAuthContext);
};

export const AUTH_ACTION = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  INITIAL_LOAD_DONE: 'INITIAL_LOAD_DONE',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  CREATE_ADDRESS: 'CREATE_ADDRESS',
  UPDATE_ADDRESS: 'UPDATE_ADDRESS',
  FAVORITE_ADDRESS: 'FAVORITE_ADDRESS',
  DELETE_ADDRESS: 'DELETE_ADDRESS',
};

const ACCESS_TOKEN_KEY = 'nooz_cat';
const EXPIRES_AT_KEY = 'nooz_ea';
const reducer = (state: IAuthenticationContext, action: IAction) => {
  switch (action.type) {
    case AUTH_ACTION.INITIAL_LOAD_DONE:
      return {
        ...state,
        isLoading: false,
      };
    case AUTH_ACTION.LOGIN:
      const data = action.data as IAuthLogin;
      localStorage.setItem(ACCESS_TOKEN_KEY, data.token.accessToken);
      localStorage.setItem(EXPIRES_AT_KEY, data.token.expiresAt);
      return {
        ...state,
        isAuthenticated: true,
        accessToken: data.token.accessToken,
        customer: data.customer,
        catalogB2B: data.catalogB2B,
      };
    case AUTH_ACTION.LOGOUT:
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(EXPIRES_AT_KEY);

      // Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
      return {
        ...state,
        isAuthenticated: false,
        accessToken: undefined,
        customer: undefined,
        catalogB2B: undefined,
      };
    case AUTH_ACTION.UPDATE_CUSTOMER:
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.data,
        },
      };
    case AUTH_ACTION.UPDATE_COMPANY:
      return {
        ...state,
        customer: {
          ...state.customer,
          company: action.data,
        },
      };
    case AUTH_ACTION.CREATE_ADDRESS: {
      const newState = {
        ...state,
      };
      if (
        newState?.customer?.addresses?.edges &&
        !newState.customer.addresses.edges.find(
          (a: Record<string, any>) => a.node.id === action.data?.id,
        )
      ) {
        newState.customer.addresses.edges.push({ node: action.data });
      }
      return newState;
    }
    case AUTH_ACTION.UPDATE_ADDRESS: {
      const newState = { ...state };
      if (newState?.customer?.addresses?.edges) {
        const pos = newState.customer.addresses.edges.findIndex(
          (a: Record<string, any>) => a.node?.id === action.data?.id,
        );
        newState.customer.addresses.edges[pos] = { node: action.data };
      }
      return newState;
    }
    case AUTH_ACTION.FAVORITE_ADDRESS:
      return {
        ...state,
        customer: { ...state.customer, ...action.data },
      };
    case AUTH_ACTION.DELETE_ADDRESS: {
      const newState = {
        ...state,
      };
      if (newState?.customer?.addresses?.edges)
        newState.customer.addresses.edges =
          newState.customer.addresses.edges.filter(
            ({ node }: Record<string, any>) => node.id !== action.data,
          );
      return newState;
    }
    default:
      return { ...state };
  }
};
export const AuthenticationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const [value, dispatch] = useReducer(reducer, {
    isAuthenticated: false,
    isLoading: true,
  });

  const logout = async () => {
    // await NoozNextApi.instance({
    //   ...NOOZ_NEXT_REGISTRY.LOGOUT,
    // });
    dispatch({ type: AUTH_ACTION.LOGOUT });
  };
  useEffect(() => {
    const checkLocalStorageAuth = async () => {
      try {
        let token = localStorage.getItem(ACCESS_TOKEN_KEY);
        if (token) {
          try {
            const { data } = await NoozNextApi.instance({
              ...NOOZ_NEXT_REGISTRY.REFRESH,
              headers: { 'customer-access-token': token },
            });
            token = data.accessToken as string;
            const expiresAt = data.expiresAt as string;
            const limit = new Date(expiresAt);
            const isValid = limit.getTime() - Date.now() > 0;

            if (isValid) {
              const {
                data: { customer, catalogB2B },
              } = await NoozNextApi.instance({
                ...NOOZ_NEXT_REGISTRY.CUSTOMER.GET,
                headers: { 'customer-access-token': token },
              });

              NoozNextApi.instance.defaults.headers.common[
                'customer-access-token'
              ] = token;
              dispatch({
                type: AUTH_ACTION.LOGIN,
                data: {
                  customer,
                  catalogB2B,
                  token: {
                    accessToken: token,
                    expiresAt,
                  },
                },
              });
            } else {
              await logout();
            }
          } catch (e) {
            await logout();
          }
        } else await logout();
      } catch (e) {
        await logout();
      }

      dispatch({ type: AUTH_ACTION.INITIAL_LOAD_DONE });
    };

    if (router.asPath !== 'redirect-multipass' && router.asPath !== 'auth')
      checkLocalStorageAuth();
  }, []);

  return (
    <SetAuthContext.Provider value={dispatch}>
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </SetAuthContext.Provider>
  );
};
export default useAuth;
