// @ts-nocheck
import React, { useContext } from 'react';
import { formatCfItems } from '@/helpers/formatCfItems';
import { IBlock, ILabel, ILink } from '@/@types/generated/contentful';
import {
  createStyles,
  Divider,
  Group,
  Indicator,
  NavLink,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronRight } from 'tabler-icons-react';
import { HeaderActiveTabContext, HeaderDrawerContext } from '../../Header';
import LocalisationMini from '@/components/LocalisationMini/LocalisationMini';
import HeaderMobileNavigationsMasterNavigation from './HeaderMobileNavigationsMasterNavigation';
import HeaderMobileCollectionSecondaryNavigation from './HeaderMobileNavigationsSecondaryNavigation';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import useLocalizedSlug from '@/utils/hook/useLocalizedSlug';
import usePageContext from '@/utils/context/page';
import useAuth from '@/utils/context/authentication';

const useStyles = createStyles(() => ({
  navLink: {
    padding: '3px 0px',
  },
}));

const motionProps = {
  initial: { opacity: 0, transform: 'translateX(-100px)' },
  animate: { opacity: 1, transform: 'translateX(0px)' },
  exit: { opacity: 0, transform: 'translateX(-100px)' },
  transition: { ease: 'easeOut', duration: 0.3 },
};

const HeaderMobileNavLink = ({ item, key }: { item: IBlock; key: number }) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const { headerDrawerBodyState, headerDrawerState } =
    useContext(HeaderDrawerContext);
  const { headerActiveTabState } = useContext(HeaderActiveTabContext);

  const { link, masterDetails }: { link: ILink; masterDetails: IBlock } = item;

  const href = useLocalizedSlug(link?.fields.slug?.fields.url as string);

  const handleNavClick = (item) => {
    const { masterDetails, secondaryDetails }: { masterDetails: IBlock } = item;
    headerActiveTabState[1](true);
    headerDrawerState[1]({ ...headerDrawerState[0] });
    headerDrawerBodyState[1](
      <>
        <HeaderMobileNavigationsMasterNavigation details={masterDetails} />
        {secondaryDetails && (
          <HeaderMobileCollectionSecondaryNavigation
            details={secondaryDetails}
          />
        )}
      </>,
    );
  };

  const props = {};
  if (!masterDetails) {
    props.component = 'a';
    props.href = href.pathname;
  } else {
    props.component = 'button';
    props.onClick = () => handleNavClick(item);
  }

  if (!link) {
    return null;
  }
  return (
    <>
      <NavLink
        className={classes.navLink}
        variant='subtle'
        color='gray'
        key={key}
        label={
          <Group position='apart'>
            <Indicator
              inline
              disabled={
                !link.fields.badge || link?.fields.badge?.fields.key === 'empty'
              }
              sx={{
                '& .mantine-Indicator-indicator': {
                  height: 'fit-content',
                  width: 'fit-content',
                  padding: '0px 10px',
                  transform: 'translate(105%, 0%)',
                },
              }}
              label={link.fields.badge?.fields.label?.fields.text}
              color={link.fields.badge?.fields.color?.fields.classname}>
              <NoozText
                weight='bold'
                color={theme.colors.gray[9]}
                variant={link.fields.label?.fields.variant || 'text'}
                size={24}>
                {link.fields.label?.fields.text}
              </NoozText>
            </Indicator>
            <ChevronRight
              size={20}
              strokeWidth={1.5}
            />
          </Group>
        }
        {...props}
      />
      <Divider color={theme.colors.gray[3]} />
    </>
  );
};

export default function HeaderMobileNavigationsDrawer() {
  const { layout } = usePageContext();

  const { navigations }: { navigations: IBlock } = formatCfItems(
    layout?.fields.header,
  );

  const {
    collectionsList,
    servicesList,
    stores,
    featuredCollection,
    withPrescription,
    servicesLabel,
  }: {
    collectionsList: IBlock;
    servicesList: IBlock;
    featuredCollection: IBlock;
    authList: IBlock;
    withPrescription: ILabel;
    servicesLabel: ILabel;
  } = formatCfItems(navigations);

  return (
    <AnimatePresence>
      <motion.div {...motionProps}>
        <Stack
          p='lg'
          mb={100}
          spacing='xs'>
          <Divider label={withPrescription.fields.text} />
          {featuredCollection?.fields.items?.map(
            (item: IBlock, key: number) => (
              <HeaderMobileNavLink
                item={formatCfItems(item)}
                key={`featured-${key}`}
              />
            ),
          )}
          {collectionsList?.fields.items?.map((item: IBlock, key: number) => (
            <HeaderMobileNavLink
              item={formatCfItems(item)}
              key={`collections-${key}`}
            />
          ))}
          <Divider
            mt={80}
            label={servicesLabel.fields.text}
          />
          {stores?.fields.items?.map((item: IBlock, key: number) => (
            <HeaderMobileNavLink
              item={formatCfItems(item)}
              key={`stores-${key}`}
            />
          ))}
          {servicesList?.fields.items?.map((item: IBlock, key: number) => (
            <HeaderMobileNavLink
              item={formatCfItems(item)}
              key={`services-${key}`}
            />
          ))}
          <Divider
            my='xs'
            label='Services'
          />
          <LocalisationMini />
        </Stack>
      </motion.div>
    </AnimatePresence>
  );
}
