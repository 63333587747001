//@ts-nocheck
import React, { createContext, useContext } from 'react';
import { Drawer, createStyles, useMantineTheme } from '@mantine/core';
import { TIMING_FUNCTION } from '@/utils/style/animation';
import zIndexes from '@/utils/style/zIndex';
import useHeaderHeight from '@/utils/hook/useHeaderHeight';
import CategoryFilters from '@/components/ProductCategory/CategoryFilters/CategoryFilters';

export const CategoryFilterDrawerInitState: IDrawer = {
  opened: false,
  position: 'left',
  onClose: () => {},
  zIndex: zIndexes.drawer,
  padding: 0,
  body: '',
  footer: '',
  size: '90vw',
  keepMounted: true,
};

export const CategoryFilterDrawerContext = createContext([
  CategoryFilterDrawerInitState,
  () => {},
]);

//@ts-ignore
const useStyles = createStyles((theme, { headerHeight }) => {
  return {
    inner: {
      height: `calc(100vh - ${headerHeight}px)`,
      top: headerHeight,
      '* > .mantine-Drawer-body': {
        // height: '100%',
        overflow: 'auto',
      },
    },
  };
});

export default function CategoryFiltersDrawer({ facets }: { facets?: any }) {
  const theme = useMantineTheme();
  const headerHeight = useHeaderHeight();
  const { classes } = useStyles({ headerHeight: headerHeight.wrapper });

  const categoryFiltersDrawerState = useContext(CategoryFilterDrawerContext);

  const overlayColor =
    theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2];

  return (
    <Drawer
      {...categoryFiltersDrawerState[0]}
      overlayProps={{ color: overlayColor }}
      withCloseButton={false}
      zIndex={zIndexes.drawer}
      transitionProps={{ duration: 300, timingFunction: TIMING_FUNCTION }}
      scrollAreaComponent={Drawer.NativeScrollArea}
      trapFocus={false}
      keepMounted
      classNames={classes}>
      <CategoryFilters facets={facets} />
    </Drawer>
  );
}
