import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, clsx, Drawer, Flex, Image } from '@mantine/core';
import useStaticContent from '@/utils/hook/useStaticContent';
import { isWindowDefined } from 'swr/_internal';
import lastViewedItems, { LAST_VIEWED_EVENT } from '@/helpers/lastViewedItems';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import Currency from '@/helpers/Currency';
import usePageContext from '@/utils/context/page';
import i18n from '@/helpers/i18n';
import Link from 'next/link';
import useClasses from '@/components/LastViewed/useClasses';
import LocaleHelper from '@/helpers/Locale';
import Datalayer from '@/helpers/Datalayer';
import NoozButton from '../Nooz/NoozButton/NoozButton';

interface ILastViewedContext {
  quantity: number;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const LastViewedContext = createContext<ILastViewedContext>({
  quantity: 0,
  visible: false,
  setVisible: () => true,
});

export const useLastViewed = () => useContext(LastViewedContext);

export const DISABLED_VIEWED_ITEMS =
  process.env.NEXT_PUBLIC_DISABLED_VIEWED_ITEMS === 'true';

const LastViewedButton = ({
  drawerVisible,
  setDrawerVisible,
  quantity,
}: {
  drawerVisible: boolean;
  setDrawerVisible: Dispatch<SetStateAction<boolean>>;
  quantity: number;
}) => {
  const { components } = usePageContext();
  const config: any = Array.isArray(components)
    ? {}
    : components?.config?.fields?.metaData || {};

  const classes = useClasses();
  const staticContent = useStaticContent();

  if (config.hideViewedItems) return null;
  return (
    <>
      <Box
        id={'viewed-btn'}
        className={clsx(classes.button, {
          show: !drawerVisible && quantity > 0,
        })}
        onClick={() => {
          Datalayer.openViewedItems();
          setDrawerVisible(true);
        }}>
        {`${i18n(staticContent, 'productViewedTab')?.replace(
          '{{count}}',
          quantity.toString(),
        )}`}
      </Box>
    </>
  );
};
const LastViewedDrawer = ({
  drawerVisible,
  setDrawerVisible,
  products,
  setProducts,
}: {
  drawerVisible: boolean;
  setDrawerVisible: Dispatch<SetStateAction<boolean>>;
  products: Record<string, any>[];
  setProducts: Dispatch<SetStateAction<any>>;
}) => {
  const classes = useClasses();
  const { locale, geoMarket } = usePageContext();
  const currencyCode =
    (geoMarket as any)?.currencySettings?.baseCurrency?.currencyCode || 'EUR';
  const cfLocale = useMemo(
    () => LocaleHelper.getValidContentfulLocale(locale),
    [locale],
  );
  const staticContent = useStaticContent();

  const hanldeClearProducts = () => {
    setProducts([]);
    lastViewedItems.reset();
    setDrawerVisible(false);
  };

  return (
    <Drawer
      opened={drawerVisible}
      onClose={() => setDrawerVisible(false)}
      classNames={{ inner: classes.innerDrawer }}
      transitionProps={{ duration: 250, timingFunction: 'linear' }}
      overlayProps={{ opacity: 0.25, blur: 4, zIndex: 1000 }}
      withCloseButton={false}
      size={'auto'}
      position={'bottom'}>
      <Flex className={classes.wrapper}>
        <Flex justify='space-between'>
          {staticContent['productViewedSubTitle'] ? (
            <Flex className={classes.labelWrapper}>
              {staticContent['productViewedSubTitle']?.replace(
                '{{count}}',
                products.length.toString(),
              )}
            </Flex>
          ) : null}
          {products.length > 0 && (
            <NoozButton
              onClick={hanldeClearProducts}
              variant='light'
              color='black'
              size='xs'>
              {staticContent['productViewedReset']}
            </NoozButton>
          )}
        </Flex>
        <Flex className={classes.productList}>
          {products.map((hit) => {
            return (
              <Link
                onClick={() => {
                  setDrawerVisible(false);
                  Datalayer.clickViewedItem(
                    hit.defaultProductVariant?.fields.title,
                  );
                }}
                key={hit.defaultProductVariant.id}
                href={(
                  `/${locale || ''}/${
                    hit.localizedSlugs[cfLocale] || hit.localizedSlugs.en || ''
                  }${hit.search || ''}` || ''
                ).replaceAll(/\/\//g, '/')}
                style={{ all: 'unset' }}>
                <Flex className={classes.productWrapper}>
                  <Image
                    width={150}
                    src={
                      (
                        hit.defaultProductVariant?.fields?.image ||
                        (hit.defaultProductVariant?.fields?.images || [])?.[0]
                      )?.fields?.media?.fields?.file.url + '?fm=webp'
                    }
                    alt={hit.defaultProductVariant?.fields?.title}
                  />
                  <Flex
                    my={10}
                    gap={10}>
                    <Box sx={{ flex: 1 }}>
                      <NoozText className={classes.productLabel}>
                        {`${hit.product.fields?.label.fields?.text}`}
                        {hit.defaultProductVariant?.fields?.color?.fields
                          .text ? (
                          <span
                            className={
                              classes.colorLabel
                            }>{` - ${hit.defaultProductVariant?.fields?.color?.fields.text}`}</span>
                        ) : null}
                      </NoozText>
                    </Box>
                    <Box>
                      <NoozText>{`${
                        hit.currencyPrices[currencyCode].amount
                      } ${Currency.toSymbol(
                        hit.currencyPrices[currencyCode].currencyCode,
                      )}`}</NoozText>
                    </Box>
                  </Flex>
                </Flex>
              </Link>
            );
          })}
        </Flex>
      </Flex>
    </Drawer>
  );
};

const LastViewed = ({ children }: PropsWithChildren) => {
  const [loaded, setLoaded] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [products, setProducts] = useState(lastViewedItems.get());
  const { locale } = usePageContext();

  useEffect(() => {
    const listener = () => {
      setProducts(lastViewedItems.get());
    };
    if (isWindowDefined) {
      setLoaded(true);
      window.addEventListener(LAST_VIEWED_EVENT, listener);
    }

    return () => {
      if (isWindowDefined) {
        window.removeEventListener(LAST_VIEWED_EVENT, listener);
      }
    };
  }, []);

  return (
    <LastViewedContext.Provider
      value={{
        quantity: products.length,
        visible: drawerVisible,
        setVisible: setDrawerVisible,
      }}>
      {children}
      {locale && loaded && !DISABLED_VIEWED_ITEMS ? (
        <>
          <LastViewedButton
            drawerVisible={drawerVisible}
            setDrawerVisible={setDrawerVisible}
            quantity={products.length}
          />
          <LastViewedDrawer
            drawerVisible={drawerVisible}
            setDrawerVisible={setDrawerVisible}
            setProducts={setProducts}
            products={products}
          />
        </>
      ) : null}
    </LastViewedContext.Provider>
  );
};

export default LastViewed;
