import {
  createStyles,
  Group,
  SimpleGrid,
  Accordion,
  Image,
  Container,
} from '@mantine/core';
import usePageContext from '@/utils/context/page';
import { formatCfItems } from '@/helpers/formatCfItems';
import { IBlock, IImage, ILink } from '@/@types/generated/contentful';
import TextLink from '@/components/Link/TextLink';
import FooterGridElement, {
  FooterLocalisation,
} from '@/components/Footer/FooterGridElement';
import NOOZLetterSubscribe from '../NoozLetterSubscribe/NoozLetterSubscribe';
import { useContainerStyles } from '@/utils/style/useContainer';

const useStyles = createStyles((theme) => ({
  footer: {
    background: '#000',
    paddingTop: 60,
    paddingBottom: 60,
    [theme.fn.smallerThan('sm')]: {
      paddingBottom: 60 + 110,
    },
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('sm')]: {
      padding: 0,
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  grid: {
    width: '100%',
  },

  link: {
    display: 'block',
    color: theme.colors.gray[0],
    fontSize: theme.fontSizes.sm,
    paddingTop: 3,
    paddingBottom: 3,

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  afterFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 60,
    paddingTop: 30,
    paddingBottom: 30,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      padding: 0,
      paddingTop: 20,
    },
  },

  payments: {
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xl,
    },
  },

  control: {
    paddingLeft: 0,
    paddingBottom: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    color: theme.white,
    fontSize: '18px',
    fontWeight: 500,
  },
  chevron: {
    color: theme.white,
  },
  item: {
    color: theme.white,
    border: `0 solid transparent`,
    zIndex: 0,
    transition: 'transform 150ms ease',
  },
  content: {
    padding: 0,
    paddingBottom: 16,
  },
}));

const Footer = () => {
  const { classes } = useStyles();
  const containerStyles = useContainerStyles();
  const {
    layout: {
      // @ts-ignore
      fields: { footer: cfFooter },
    },
  } = usePageContext();
  const {
    localization,
    payments,
    legal,
    customerService,
    services,
    social,
    navigation,
  } = formatCfItems(cfFooter);

  return (
    <>
      <NOOZLetterSubscribe />
      <footer className={classes.footer}>
        <Container className={containerStyles.classes.container}>
          <SimpleGrid
            verticalSpacing={88}
            spacing={48}
            cols={4}
            breakpoints={[
              { maxWidth: 'md', cols: 2, spacing: 'sm', verticalSpacing: 44 },
              { maxWidth: 'xs', cols: 1, spacing: 'sm', verticalSpacing: 20 },
            ]}
            className={classes.grid}>
            <FooterLocalisation
              {...formatCfItems(localization)}
              color={localization.fields.color}
            />
            <FooterGridElement
              {...formatCfItems(customerService)}
              color={customerService.fields.color}
            />
            <FooterGridElement
              {...formatCfItems(services)}
              color={services.fields.color}
            />
            <FooterGridElement
              {...formatCfItems(social)}
              color={social.fields.color}
            />

            {(navigation as IBlock).fields.items?.map((i, index) => {
              return (
                <Accordion
                  key={index}
                  multiple
                  value={(navigation as IBlock).fields.items?.map(
                    (i) => formatCfItems(i).title.id,
                  )}
                  classNames={classes}>
                  <FooterGridElement
                    key={(i as unknown as { id: string }).id}
                    accordionMobile={true}
                    {...formatCfItems(i)}
                    color={(i as IBlock).fields.color}
                    linkColor={'#ffffff'}
                  />
                </Accordion>
              );
            })}
          </SimpleGrid>
        </Container>
        <Container
          size={'xl'}
          className={classes.afterFooter}>
          <Group
            spacing={'xs'}
            noWrap>
            {legal.fields.items.map((link: ILink) => {
              return link.fields.slug?.fields.url ? (
                <TextLink
                  color={'#9E9999'}
                  facet={link.fields.slug.fields.facet}
                  url={link.fields.slug?.fields.url}
                  text={link.fields.label?.fields.text}
                  key={(link as unknown as { id: string }).id}
                  {...link}
                />
              ) : null;
            })}
          </Group>

          <Group
            spacing={'xs'}
            className={classes.payments}
            position='right'
            noWrap>
            {payments.fields.items.map((img: IImage) => {
              return img.fields.media?.fields.file.url ? (
                <Image
                  key={(img as unknown as { id: string }).id}
                  height={26}
                  src={
                    'https:' + img.fields.media?.fields.file.url + '?fm=webp'
                  }
                  alt={img.fields.altText || ''}
                />
              ) : null;
            })}
          </Group>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
