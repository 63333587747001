import { createStyles } from '@mantine/core';

const useClasses = () =>
  createStyles((theme) => ({
    button: {
      backdropFilter: 'blur(10px)',
      backgroundColor: 'rgb(255 255 255 / 0.6)',
      borderBottom: `1px solid ${theme.colors.black[4]}`,
      borderLeft: `1px solid ${theme.colors.black[4]}`,
      borderRadius: '10px 0 0 10px',
      borderTop: `1px solid ${theme.colors.black[4]}`,
      cursor: 'pointer',
      fontColor: 'black',
      fontSize: 14,
      left: -50,
      padding: '20px 5px',
      position: 'fixed',
      top: '50%',
      transform: 'rotateZ(180deg) translate(0, 50%)',
      transition: 'all 250ms linear',
      writingMode: 'vertical-lr',
      zIndex: 2,
      '&:hover': {
        backgroundColor: 'rgb(255 255 255)',
      },
      '&.show': {
        left: 0,
      },
      [theme.fn.smallerThan(theme.breakpoints.xs)]: {
        display: 'none',
      },
    },
    iconAction: {
      position: 'fixed',
      bottom: 16,
      left: 16,
      backgroundColor: theme.colors.white,
      cursor: 'pointer',
      '&.with-sticky-footer': {
        bottom: 80,
      },
      '&:not(.show)': {
        display: 'none',
      },
    },
    innerDrawer: { zIndex: 1002 },
    wrapper: {
      flexDirection: 'column',
      alignItems: 'left ',
      gap: 10,
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        flexDirection: 'column',
        gap: '1rem',
      },
    },
    labelWrapper: {
      height: '100%',
      justifyItems: 'center',
      fontSize: 18,
      fontWeight: 'bolder',
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        aspectRatio: 'unset',
      },
    },
    productList: {
      flex: 1,
      overflow: 'auto',
      gap: 30,
      [theme.fn.smallerThan(theme.breakpoints.sm)]: {
        width: '100%',
      },
    },
    productWrapper: {
      flexDirection: 'column',
      width: 150,
      cursor: 'pointer',
    },
    productLabel: {
      fontWeight: 'bold',
      color: theme.colors.black[9],
    },
    colorLabel: {
      fontWeight: 'normal',
      color: theme.colors.black[6],
    },
  }))().classes;

export default useClasses;
