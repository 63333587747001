import {
  ActionIcon,
  Box,
  Flex,
  Grid,
  Group,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import NoozText from '@/components/Nooz/NoozText/NoozText';
import usePageContext from '@/utils/context/page';
import { formatCfItems } from '@/helpers/formatCfItems';
import { IImage, ILabel, IPath } from '@/@types/generated/contentful';
import ActionCardBackdrop from '@/components/Cards/ActionCardBackdrop';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';

export const useCartSideViewDrawerProps = () => {
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);

  return { size: isDesktop ? '40vw' : '90vw' };
};

const EmptyCartSideView = ({ onClose }: { onClose: any }) => {
  const { layout } = usePageContext();
  const { emptyCartLabel, emptyBlock } = formatCfItems(layout?.fields.cart);

  return (
    <Flex
      direction='column'
      justify='space-between'
      bg={'white'}
      h='100%'
      sx={{ overflow: 'hidden' }}>
      <Group
        p={'16px 16px 8px'}
        position={'apart'}>
        <NoozText size={18}>{emptyCartLabel.fields.text}</NoozText>
        <ActionIcon
          color={'title'}
          onClick={onClose}
          variant={'outline'}
          radius={99}>
          <NoozIcon iconKey={'X'} />
        </ActionIcon>
      </Group>
      <Grid
        gutter={26}
        p={26}
        sx={{ overflow: 'auto' }}>
        {emptyBlock?.fields.items?.map((item: any) => {
          const { label } = formatCfItems(item) as { label: ILabel };
          const slug = item.fields.items.find(
            (i: any) => i?.fields.url,
          ) as IPath;
          const img = item.fields.items.find(
            (i: any) => i?.fields.media,
          ) as IImage;

          return (
            <Grid.Col
              key={item.id}
              span={6}>
              <Box h={{ base: 300 }}>
                <ActionCardBackdrop
                  image={img.fields.media}
                  slug={slug}
                  label={label}
                />
              </Box>
            </Grid.Col>
          );
        })}
      </Grid>
    </Flex>
  );
};

export default EmptyCartSideView;
