import NextLink from 'next/link';
import usePageContext from '@/utils/context/page';
import React, { useMemo } from 'react';
import { clsx, createStyles, MantineNumberSize } from '@mantine/core';
import { IFacet, IIconFields } from '@/@types/generated/contentful';
import NoozIcon from '@/components/Nooz/NoozIcon/NoozIcon';
import { Property } from 'csstype';
import NoozText from '../Nooz/NoozText/NoozText';

export const underLine = (color: string) => ({
  content: "''",
  background: color,
  display: 'block',
  position: 'absolute',
  bottom: '2px',
  left: '0',
  width: '0',
  height: '1px',
  transition: 'all 0.2s ease-in-out',
});

// @ts-ignore
const useStyles = createStyles((theme, { type, color, isFadeIn }) => ({
  link: {
    textDecoration: ['no-underline', 'animated-underline'].includes(type)
      ? 'none'
      : undefined,
    textDecorationColor: color,
    display: 'flex',
    alignItems: 'center',
  },
  iconStart: {
    marginRight: '1rem',
  },
  text: {
    display: 'inline-block',
    position: 'relative',
    transition: 'all 0.3s ease-in-out',
    opacity: isFadeIn ? 0.8 : 1,
    // @ts-ignore
    '&:before':
      type === 'animated-underline'
        ? underLine(color || theme.colors.dark)
        : {},
    '&:hover::before': {
      width: '100%',
    },
    '&:hover': {
      opacity: 1,
    },
  },
}));

interface ITextLink {
  rel?: string;
  type?: 'underline' | 'no-underline' | 'animated-underline';
  className?: string;
  color?: string;
  isFadeIn?: boolean;
  icon?: IIconFields;
  size?: MantineNumberSize;
  weight?: Property.FontWeight | undefined;
  text?: string;
  url?: string;
  facet?: IFacet[];
  asFor?: 'span';
}

const TextLink = ({
  url,
  color,
  text,
  size,
  weight,
  isFadeIn,
  className,
  icon,
  type = 'no-underline',
  facet,
  asFor,
  rel,
}: ITextLink) => {
  const { locale } = usePageContext();
  const hasUrl = url || url === '';

  const Component = useMemo(
    () =>
      hasUrl && !asFor
        ? /^(https:\/\/|mailto:|tel:)/g.test(url)
          ? 'a'
          : NextLink
        : asFor || null,
    [url],
  );

  const generateProps = (
    hasUrl: boolean,
    url: string,
    locale: string,
    facet?: IFacet[],
  ): Record<string, any> => {
    if (hasUrl && /^(https:\/\/|mailto:|tel:)/g.test(url)) {
      return { href: url, target: '_blank', rel: 'noreferrer' };
    }

    const queryParams =
      facet?.map((f) => `${f.fields.key}=${f.fields.value}`).join('&') || '';
    const href = `/${locale}/${url}${queryParams && `?${queryParams}`}`.replace(
      /\/\//,
      '/',
    );

    return { href };
  };

  const props = useMemo<Record<string, any>>(
    () =>
      generateProps(hasUrl as boolean, url as string, locale as string, facet),
    [locale, url, hasUrl, facet],
  );

  // @ts-ignore
  const { classes } = useStyles({ type, color, isFadeIn });

  return Component
    ? React.createElement(
        Component,
        // @ts-ignore
        {
          ...props,
          className: clsx(classes.link, className),
        },
        <>
          {icon ? (
            <NoozIcon
              {...icon}
              strokeWidth={1}
              color={color || 'dark'}
              className={classes.iconStart}
            />
          ) : null}
          <NoozText
            className={classes.text}
            size={size}
            color={color || 'dark'}
            weight={weight}
            underline={false}>
            {text}
          </NoozText>
        </>,
      )
    : null;
};

export default TextLink;
