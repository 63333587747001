export const LAST_VIEWED_KEY = 'last_viewed_v0.2';
export const LIMIT_DAYS = 10;

export const LAST_VIEWED_EVENT = 'last_viewed-event';
const lastViewedItems = {
  set: (hit: Record<string, any>, localizedPrice?: any) => {
    try {
      let viewed = JSON.parse(localStorage.getItem(LAST_VIEWED_KEY) || '[]');
      const existingId = viewed.findIndex(
        (v: Record<string, any>) => hit.product.id === v.product.id,
      );

      if (existingId >= 0) {
        viewed.splice(existingId, 1);
      }
      viewed.unshift({
        ...hit,
        timestamp: new Date().getTime(),
        currencyPrices:
          localizedPrice[hit.defaultProductVariant.shopifyInfo.sku],
      });

      const limitTs = new Date().getTime() - LIMIT_DAYS * 24 * 60 * 60 * 1000; // days at millisecond format
      viewed = viewed.filter(
        (e: Record<string, any>) => e.timestamp >= limitTs,
      );
      localStorage.setItem(LAST_VIEWED_KEY, JSON.stringify(viewed));
      window.dispatchEvent(new Event(LAST_VIEWED_EVENT));
    } catch (e) {}
  },
  get: () => {
    try {
      let viewed = JSON.parse(localStorage.getItem(LAST_VIEWED_KEY) || '[]');
      const length = viewed.length;

      const limitTs = new Date().getTime() - LIMIT_DAYS * 24 * 60 * 60 * 1000; // days at millisecond format
      viewed = viewed.filter(
        (e: Record<string, any>) => e.timestamp >= limitTs,
      );
      if (length !== viewed.length) {
        localStorage.setItem(LAST_VIEWED_KEY, JSON.stringify(viewed));
        window.dispatchEvent(new Event(LAST_VIEWED_EVENT));
      }

      return viewed;
    } catch (e) {
      return [];
    }
  },
  reset: () => {
    try {
      localStorage.setItem(LAST_VIEWED_KEY, JSON.stringify([]));
    } catch (e) {}
  },
};

export default lastViewedItems;
